<template>
  <div class="px-6">
    <div class="pt-5 pb-2 px-2">
      <div class="d-flex w-100 justify-space-between">
        <span>
          <p class="gray--text mr-4 mt-1">
            Attraction Categories ({{ total }})
          </p>
        </span>
        <base-input
          placeholder="Search Categories"
          @input="searchCategories"
          :rules="[]"
          clearable
        ></base-input>
      </div>
      <div class="mt-4">
        <div
          class="py-2 text-center radius-5 secondary cursor-pointer"
          @click="createHandler"
        >
          <span class="white--text font-16">Add New Category</span>
        </div>
      </div>
    </div>
    <div class="categories-table">
      <!-- table -->
      <v-data-table
        :server-items-length="total"
        :headers="headers"
        :items="listItems"
        :items-per-page="20"
        hide-default-footer
        item-key="_id"
        class="elevation-0"
        @click:row="editHandler"
        mobile-breakpoint="0"
        :item-class="
          () => {
            return 'cursor-pointer';
          }
        "
      >
        <!-- name & email -->
        <template v-slot:item.name="{ item }">
          <span class="d-block font-13 white-grey--text">{{ item.name }}</span>
          <span class="d-block font-12 grey--text">{{ item.email }}</span>
        </template>

        <!-- actions -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex mx-auto">
            <div
              class="d-block icon-cover mr-1 cursor-pointer base-btn"
              @click="editCompanionValues(item, $event)"
            >
              <v-icon class="mx-2">mdi-percent-outline</v-icon>
            </div>
            <div
              class="d-block icon-cover mr-1 cursor-pointer base-btn"
              @click="deleteConfirm(item, $event)"
            >
              <v-icon class="mx-2" color="red">mdi-delete</v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="pagination-count text-center">
      <v-pagination
        v-model="page"
        :length="totalFilter"
        circle
        @input="fetchList"
      ></v-pagination>
    </div>

    <!-- add category -->
    <v-dialog v-model="dialog" content-class="white px-6 py-7" max-width="600">
      <miniguide-category
        @close="dialog = false"
        @added="addedHandler"
        :key="dialog"
        :isEdit="isEdit"
        :filters="filters"
        :item="selectedItem"
      ></miniguide-category>
    </v-dialog>

    <!-- delete category -->
    <v-dialog v-model="deleteDialog" max-width="600px">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
    <v-dialog v-model="editCompanionValuesDialog" max-width="600px">
      <edit-attraction-category-companion
        width="300"
        :key="editCompanionValuesDialog"
        :item="selectedItem"
        @close="closeEditCompanionValuesDialog"
        @reloadData="fetchList"
      ></edit-attraction-category-companion>
    </v-dialog>
  </div>
</template>

<script>
import MiniguideCategory from "../../components/dialogs/MiniguideCategory.vue";
import EditAttractionCategoryCompanion from "@/components/dialogs/Attraction/CategoryCompanion.vue";

export default {
  components: {
    MiniguideCategory,
    EditAttractionCategoryCompanion
  },
  data: () => ({
    avatar:
      "https://www.ekoparkotomasyon.com/wp-content/public_html/cart/image/data/uploads/2013/12/default-placeholder.png",
    headers: [
      { text: "Name", value: "name" },
      { text: "", value: "actions", width: "110px", sortable: false }
    ],
    listItems: [],
    filters: [],
    pageSize: 30,
    page: 1,
    total: 0,
    dialog: false,
    isEdit: false,
    deleteDialog: false,
    selectedItem: {},
    item: "",
    editCompanionValuesDialog: false,
    searchWord: ""
  }),
  computed: {
    totalFilter() {
      return Math.ceil(+(this.total / 20));
    }
  },
  methods: {
    createHandler() {
      this.isEdit = false;
      this.dialog = true;
    },
    editCompanionValues(item, event) {
      event.stopPropagation();
      this.selectedItem = item;
      this.editCompanionValuesDialog = true;
    },
    closeEditCompanionValuesDialog() {
      this.selectedItem = undefined;
      this.editCompanionValuesDialog = false;
    },
    deleteConfirm(item, event) {
      this.deleteDialog = true;
      this.item = item._id;
      event.stopPropagation();
    },
    async editHandler(item) {
      let { data } = await this.$axios.get(
        `/miniguide/category/dashboard/${item._id}`
      );
      if (data) {
        this.selectedItem = data.data;
        this.dialog = true;
        this.isEdit = true;
      }
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/miniguide/category/${this.item}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        await this.fetchList();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    addedHandler() {
      this.dialog = false;
      this.fetchList();
    },
    async searchCategories(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
    },
    async fetchList() {
      let { data } = await this.$axios.get(
        `miniguide/category/dashboard?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }&pageSize=${this.pageSize}&pageNumber=${this.page}&sort=0`
      );
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
    },
    async fetchFilters() {
      let { data } = await this.$axios.get(`filters`);
      if (data) {
        this.filters = data;
      }
    }
  },
  async created() {
    await this.fetchList();
    await this.fetchFilters();
  }
};
</script>

<style lang="scss" scoped>
.header {
  overflow: hidden;
  height: 80px;
}
.categories-table {
  overflow-y: auto;
  height: calc(100vh - 80px - 100px);

  .mdi-arrow-up {
    display: none;
  }
}
</style>
