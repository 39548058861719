<template>
  <div class="collection-form white pa-5 py-3">
    <div class="max-width d-flex">
      <div class="mt-3 d-flex">
        <span
          class="main-title d-block my-2 black--text transition-3s font-20 font-500"
        >
          {{ item.name }} - Companion times and types weights
        </span>
      </div>
    </div>
    <div class="divider-dark mb-2"></div>
    <!-- inputs -->
    <div class="attraction-input px-4 py-6" v-if="loading">
      <v-row class="px-8 mt-2" v-if="loading">
        <v-col
          cols="12"
          md="12"
          v-for="(item, i) in [1, 2, 3, 4, 5, 6]"
          :key="i"
        >
          <v-skeleton-loader type="text"> </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="attraction-input px-4 py-6" v-if="!loading">
      <v-form v-model="valid" @submit.prevent="submitHandler" ref="form">
        <v-row>
          <v-col cols="12" :md="12">
            <div v-if="item">
              <!-- inputs -->
              <div class="companion-input">
                <v-row>
                  <v-col cols="12" md="6">
                    <span class="d-block mb-4 font-18 font-500">Times</span>
                    <v-row v-for="(input, i) in timesSchema" :key="i">
                      <v-col cols="4" v-if="input.time">
                        <span class="word-wrap">
                          {{ input.time.title }}
                        </span>
                      </v-col>
                      <v-col cols="5">
                        <base-input
                          type="number"
                          v-model="input['score']"
                          :rules="[
                            (v) => `${v}`.length > 0 && v >= 0 && v <= 10
                          ]"
                        ></base-input>
                      </v-col>
                      <v-col cols="3"> / 10 </v-col>
                    </v-row>
                  </v-col>

                  <!-- type score -->
                  <v-col cols="12" md="6">
                    <span class="d-block mb-4 font-18 font-500">Types</span>
                    <v-row
                      v-for="(input, i) in typesSchema"
                      :key="i"
                    >
                      <v-col cols="4" v-if="input.typeId">
                        <span class="word-wrap">
                          {{ input.typeId.title }}
                        </span>
                      </v-col>
                      <v-col cols="5">
                        <base-input
                          type="number"
                          v-model="input['score']"
                          :rules="[
                            (v) => `${v}`.length > 0 && v >= 0 && v <= 10
                          ]"
                        ></base-input>
                      </v-col>
                      <v-col cols="3"> / 10 </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <buttons
      @cancel="close"
      @next="submitHandler"
      text="Save"
      :isValid="valid"
      :key="valid"
    ></buttons>
  </div>
</template>
<script>
import Buttons from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    Buttons
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    editedAttractionCategory: undefined,
    locations: [],
    valid: false,
    loading: true
  }),
  methods: {
    close() {
      this.loading = false;
      this.$emit("close");
    },
    async fetchItemByID() {
      if (this.item && this.item._id) {
        this.loading = true;
        let timesSchemaData = await this.$axios.get(`/time`);
        let typesSchemaData = await this.$axios.get(`/type`);
        let { data } = await this.$axios.get(
          `/miniguide/category/dashboard/${this.item._id}`
        );

        if (data) {
          this.editedAttractionCategory = data.data;

          if (this.editedAttractionCategory.timeScores) {
            this.times = this.editedAttractionCategory.timeScores;
          }

          this.timesSchema = timesSchemaData.data.times.map((time) => {
            let objectTime = this.times?.find((t) => {
              return t.time._id == time._id;
            });
            return {
              score: objectTime ? objectTime.score : 0,
              _id: objectTime?._id,
              time: {
                title: time.title,
                _id: time._id
              }
            };
          });

          if (this.editedAttractionCategory.timeScores) {
            this.types = this.editedAttractionCategory.typeScores;
          }

          this.typesSchema = typesSchemaData.data.types.map((type) => {
            let objectType = this.types?.find((t) => {
              return t.typeId._id == type._id;
            });
            return {
              score: objectType ? objectType.score : 0,
              _id: objectType?._id,
              typeId: {
                title: type.title,
                _id: type._id
              }
            };
          });
        }
        this.loading = false;
      }
    },
    async submitHandler() {
      if (this.editedAttractionCategory && this.editedAttractionCategory._id) {
        let res = await this.$axios.put(
          `miniguide/category/${this.editedAttractionCategory._id}`,
          {
            typeScores: this.typesSchema,
            timeScores: this.timesSchema
          }
        );
        if (res.data) {
          this.$store.dispatch("showSnack", {
            text: "Edited succesfully",
            color: `success`
          });
          this.$emit("close");
        } else {
          this.$store.dispatch("showSnack", {
            text: res.message,
            color: `erroe`
          });
        }
      }
    }
  },
  async created() {
    await this.fetchItemByID();
  }
};
</script>
