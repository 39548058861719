var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"miniguide-editor"},[_c('div',{staticClass:"miniguide-editor-header d-flex align-center justify-space-between b-bottom"},[_c('span',{staticClass:"d-block font-16 font-500"},[_vm._v(_vm._s(_vm.isEdit ? "Edit " : "New ")+" Attraction Category")]),_c('v-icon',{attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1),_c('base-select',{attrs:{"items":_vm.$root.languages,"placeholder":"Language","returnObject":false},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('v-form',{staticClass:"pt-4 attraction-editor",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"miniguide-editor-form py-2"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('base-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.obj.name[_vm.language]),callback:function ($$v) {_vm.$set(_vm.obj.name, _vm.language, $$v)},expression:"obj.name[language]"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"hide-details":true,"label":"Featured","dense":""},model:{value:(_vm.obj.featured),callback:function ($$v) {_vm.$set(_vm.obj, "featured", $$v)},expression:"obj.featured"}})],1),_c('v-col',{key:_vm.updateKey,staticClass:"py-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-20 mb-0"},[_vm._v("Filters")]),_vm._l((_vm.filters),function(filter){return _c('div',{key:filter._id},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.obj.filters &&
              _vm.obj.filters.some((it) => {
                return it._id === filter._id;
              })},on:{"change":function($event){return _vm.filterChangeHandler(filter)}}}),_vm._v(" "+_vm._s(filter.name[_vm.language])+" "),(
              _vm.obj.filters &&
              _vm.obj.filters.some((it) => {
                return it._id === filter._id;
              })
            )?_c('v-col',{key:_vm.updateKey,staticClass:"py-1",attrs:{"cols":"12"}},_vm._l((_vm.propsDic[filter._id] || []),function(prop){return _c('div',{key:prop._id},[_c('input',{staticClass:"ml-3",attrs:{"rules":[(v) => (v && v.length > 0) || ''],"type":"checkbox"},domProps:{"checked":_vm.obj.props &&
                  _vm.obj.props.some((pr) => {
                    return pr._id === prop._id;
                  })},on:{"change":function($event){return _vm.propsChangeHandler(prop)}}}),_vm._v(" "+_vm._s(prop.name[_vm.language])+" ")])}),0):_vm._e()],1)})],2)],1),_c('v-col',{staticClass:"d-flex align-center justify-space-around py-2",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.valid,"dark":_vm.valid,"color":"secondary","width":"150px"}},[_c('span',{staticClass:"font-13"},[_vm._v("Submit")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }