<template>
  <div class="miniguide-editor">
    <div
      class="miniguide-editor-header d-flex align-center justify-space-between b-bottom"
    >
      <span class="d-block font-16 font-500"
        >{{ isEdit ? "Edit " : "New " }} Attraction Category</span
      >
      <v-icon @click="$emit('close')" size="20" color="primary"
        >mdi-close</v-icon
      >
    </div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <v-form class="pt-4 attraction-editor" v-model="valid" @submit.prevent="submitHandler">
      <v-row class="miniguide-editor-form py-2">
        <!-- category name -->
        <v-col class="py-1" cols="12" md="6">
          <base-input placeholder="Name" v-model="obj.name[language]"></base-input>
        </v-col>

        <!-- featured -->
        <v-col class="py-1" cols="12">
          <v-checkbox
            :hide-details="true"
            label="Featured"
            dense
            v-model="obj.featured"
          ></v-checkbox>
        </v-col>

        <!-- Filters -->
        <v-col class="py-1" cols="12" :key="updateKey">
          <p class="font-20 mb-0">Filters</p>
          <div v-for="filter in filters" :key="filter._id">
            <input
              type="checkbox"
              :checked="
                obj.filters &&
                obj.filters.some((it) => {
                  return it._id === filter._id;
                })
              "
              @change="filterChangeHandler(filter)"
            />
            {{ filter.name[language] }}
            <!-- props -->
            <v-col
              class="py-1"
              cols="12"
              v-if="
                obj.filters &&
                obj.filters.some((it) => {
                  return it._id === filter._id;
                })
              "
              :key="updateKey"
            >
              <div v-for="prop in propsDic[filter._id] || []" :key="prop._id">
                <input
                  class="ml-3"
                  :rules="[(v) => (v && v.length > 0) || '']"
                  type="checkbox"
                  :checked="
                    obj.props &&
                    obj.props.some((pr) => {
                      return pr._id === prop._id;
                    })
                  "
                  @change="propsChangeHandler(prop)"
                />
                {{ prop.name[language] }}
              </div>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <!-- submit -->
      <v-col class="d-flex align-center justify-space-around py-2" cols="12">
        <v-btn
          type="submit"
          :disabled="!valid"
          :dark="valid"
          color="secondary"
          width="150px"
          ><span class="font-13">Submit</span></v-btn
        >
      </v-col>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    valid: false,
    obj: { props: [], name: {} },
    newImage: "",
    imgFile: "",
    props: [],
    updateKey: false,
    propsDic: {},
    language: "en"
  }),
  methods: {
    newImageHandler(file) {
      this.newImage = URL.createObjectURL(file);
      this.imgFile = file;
    },
    async propsChangeHandler(prop) {
      if (
        this.obj.props.some((item) => {
          return item._id === prop._id;
        })
      ) {
        this.obj.props = this.obj.props.filter((item) => {
          return item._id !== prop._id;
        });
      } else {
        this.obj.props.push(prop);
      }
    },
    async filterChangeHandler(filter) {
      if (
        this.obj.filters.some((item) => {
          return item._id == filter._id;
        })
      ) {
        this.obj.filters = this.obj.filters.filter((item) => {
          return item._id !== filter._id;
        });
      } else {
        this.obj.filters.push(filter);
      }
      this.obj.filters.forEach(async (filter) => {
        let { data } = await this.$axios.get(`/props?filterType=${filter._id}`);
        this.propsDic[filter._id] = data.sort((a, b) =>
          a.name[this.language].localeCompare(b.name[this.language])
        );
        this.updateKey = !this.updateKey;
      });
      if (this.obj.filters.length == 0) {
        this.updateKey = !this.updateKey;
      }
    },
    async submitHandler() {
      let objReq = {
        ...this.obj,
        country: this.$root.country.short
      };
      let res;
      if (this.isEdit) {
        res = await this.$axios.put(
          `/miniguide/category/${this.item._id}`,
          objReq
        );
      } else {
        res = await this.$axios.post(`/miniguide/category`, objReq);
      }
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Miniguie Category Created Successfully",
          color: "success"
        });
        this.$emit("added");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async fetchItemID() {
      if (this.isEdit) {
        this.obj = this.item;
        this.obj.props = [];
        this.obj.filters = this.item.selectedFilters;
        this.item.selectedFilters.forEach(async (filter) => {
          // get the props
          let { data } = await this.$axios.get(
            `/props?filterType=${filter._id}`
          );
          if (data) {
            this.propsDic[filter._id] = data;
            filter.props.forEach((prop) => {
              this.obj.props.push(prop);
            });
            this.updateKey = !this.updateKey;
          }
        });
      } else {
        this.obj = {
          filters: [],
          props: [],
          name: {}
        };
      }
      this.obj.country = this.$root.country.short;
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.fetchItemID();

  }
};
</script>

<style>
.miniguide-editor {
  height: 80vh;
  .miniguide-editor-header {
    height: 20px;
  }
  .miniguide-editor-form {
    overflow: auto;
    overflow-x: hidden;
    height: calc(80vh - 50px - 30px);
  }
  .miniguide-editor-footer {
    height: 20px;
  }
}
</style>
